/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import HeaderForwardFacing from "./header_forwardfacing"
import BgImage from "../images/bright_portal_bg.jpg"
import ElevateLogo from "../images/elevate_url_logo_white.png"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import "./layout.css"

const LayoutForwardFacing = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQueryForwardFacing {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    
    <div style={{backgroundImage:"url('"+BgImage+"')",
                backgroundPosition:"center",
                backgroundRepeat: "no-repeat",
                backgroundSize:"cover",
                backgroundColor:"#1e174a",
                minHeight:"1300px"}}>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
        integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
        crossorigin="anonymous"
      />
      <HeaderForwardFacing siteTitle={data.site.siteMetadata.title} />
      <Container>
        <Row>
          <Col>
          <main>{children}</main>
          <footer>
            <div style={{textAlign:"center",paddingTop:"40px"}}>
            {/* } © {new Date().getFullYear()} <br/> */}
            <img src={ElevateLogo} alt="Elevate Logo" style={{width:"160px"}}/>
            </div>
          </footer>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

LayoutForwardFacing.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutForwardFacing
