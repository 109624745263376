import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../images/elevate_boss_white_logo.png"
import Button from 'react-bootstrap/Button'

const HeaderForwardFacing = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.0rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <div style={{ }}>
        <div style={{marginLeft:"auto",marginRight:"auto",textAlign:"center",paddingTop:"40px"}}>
          <Link
            to="/"
            style={{
              textDecoration: `none`,
            }}
          >
          <img src={Logo} style={{ width: "400px" }} alt="Elevate Boss Logo"/>
          </Link>
          <h4>ONE singular solution that empowers brokerages and its agents with the tools, insights, &amp; productivity to manage business.</h4>
          
        </div>
      </div>
    </div>
  </header>
)

HeaderForwardFacing.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderForwardFacing.defaultProps = {
  siteTitle: ``,
}

export default HeaderForwardFacing
