import React, { Component } from "react"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import moment from "moment";
import "../components/datetime.css"
import FormikDateTime from "../components/formik_datetime"
import Screenshot from "../images/pdf_screenshot.png"

class FormLanding extends Component {
    constructor(props) {
      super(props);
      this.state = {
        formState: null,
      };
    }
  
    render() {
      const { formState } = this.state;
  
      var valid = function (current) {
        return current.day() !== 0 && current.day() !== 6;
      };
  
      var renderDay = function (props, currentDate, selectedDate) {
  
        if (currentDate.month() === moment().month()) {
          if (currentDate.date() < moment().date()) {
            if (props.className === "rdtDay") {
              props.className = "rdtDay rdtDisabled";
            }
          }
        }
        if (currentDate.month() < moment().month()) {
          props.className = "rdtDay rdtDisabled";
        }
  
        return <td {...props}>{currentDate.date()}</td>;
      };
  
      if (formState === "success") {
        return (
          <Row>
              <Col>
              <div style={{textAlign:"center"}}>
              <h5>Thank You!</h5>
                We'll be in touch asap.
                </div>
              </Col>
          </Row>
        );
      } else if (formState === "error") {
        return (
          <div style={{ maxWidth: "400px", margin: "24px auto" }}>
            <Alert color="error">An error occurred.</Alert>
          </div>
        );
      }

      return (
        <Formik
        enableReinitialize 
        initialValues={{
          firstname: this.props.firstname,
          lastname: this.props.lastname,
          company: "",
          email: this.props.email,
          phone: this.props.phone,
          mls_number: "",
          form: "boss_form",
          list: 85576,
          meetingdate: '',
          meetingtime: '',
          role: "",
          interests: '',
        }}
        validationSchema={() =>
          Yup.object().shape({
            firstname: Yup.string(),
            lastname: Yup.string(),
            company: Yup.string(),
            email: Yup.string(),
            phone: Yup.string(),
            mls_number: Yup.string()
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          //if (values.meetingdate != undefined) {
          var meeting_request = '';
          var notes = 'Notes: ';
          //console.log(values);
          Object.keys(values).forEach(function (key, index) {
            // key: the name of the object key
            // index: the ordinal position of the key within the object 
            if (key === 'meetingdate') {
              if (typeof values.meetingdate === "string") {
                var this_date = moment(values.meetingdate).format('YYYY-MM-DD'); 
                meeting_request = this_date + "T" + values.meetingtime.replace(" (EDT)", "") + "-04:00"
              }
              if (typeof values.meetingdate.format === "function") {
                meeting_request = values.meetingdate.format("YYYY-MM-DD") + "T" + values.meetingtime.replace(" (EDT)", "") + "-04:00"
              }
            }
            if (key === 'roleOther'){
              if(values.roleOther.length > 0){
                values.role = values.roleOther;
              }
            }
            if (key === 'interestsOther'){
              if(values.interestsOther.length > 0){
                notes = values.interests.join(",");
                notes = notes + "," + values.interestsOther;
              }
            }
          });
          
          const body = {
            ...values,
            notes: notes,
            utm_campaign: (window.utm_tags) ? window.utm_tags.campaign : "",
            utm_source: (window.utm_tags) ? window.utm_tags.source : "",
            utm_medium: (window.utm_tags) ? window.utm_tags.medium : "",
            utm_term: (window.utm_tags) ? window.utm_tags.term : "",
            demo_request_date: meeting_request
          };
          return fetch(
            "https://easyemerge.com/plugins/elevate_form.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
              body: JSON.stringify(body),
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.message === "ok") {
                this.setState({ formState: "success" });

                if (window.fbq) {
                  window.fbq("track", "Lead");
                }
                if (window.gtag) {
                  window.gtag("event", "conversion", {
                    send_to: "AW-980447970/2_aHCOnsjo8BEOLlwdMD",
                  });
                }
                if (window.ga) {
                  window.ga('send','event','form','form_completed','boss');
                }
              } else {
                this.setState({ formState: "error" });
              }
            })
            .catch((err) => {
              this.setState({ formState: "error" });
            });
        }}
        render={({ values, isSubmitting,handleBlur, handleChange }) => (
          <div>
          <Row>
            <Col >
            <Form noValidate>
            <div style={{ marginLeft:"auto",marginRight:"auto"}}>
            <Row style={{margin:"10px"}}>
              <Col md>
              <Field
                id="firstname"
                name="firstname"
                placeholder="First Name"
                className="form-control"
                value={values.firstname}
              />
              </Col>
              <Col md>
              <Field
                id="lastname"
                name="lastname"
                label="Last Name"
                placeholder="Last Name"
                className="form-control"
                value={values.lastname}
              />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              className="form-control"
              value={values.email}
            /></Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="phone"
              name="phone"
              label="Phone"
              placeholder="Phone"
              className="form-control"
              type="tel"
              value={values.phone}
            />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="company"
              name="company"
              label="Affiliation (optional)"
              placeholder="Affiliation (optional"
              className="form-control"
            />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
                <Field
                    id="mls_number"
                    name="mls_number"
                    placeholder="MLS # (optional)"
                    className="form-control"
                />
            </Col>
            </Row>
            <Row>
              <Col style={{textAlign:"center"}}>
                <h5>Want to talk on a specific date / time?  <br/>
Schedule it below.  Otherwise, we’ll be in touch asap.
</h5>
              </Col>
            </Row>
            <Row  style={{margin:"10px"}}>
              <Col md={8}>
                <div style={{fontSize:"15px"}}>
                  Call Date
                </div>
                <Field name="meetingdate" timeFormat={false} component={FormikDateTime} isValidDate={valid} renderDay={renderDay}/>
              </Col>
              <Col md={3}>
                <label style={{fontSize:"15px",marginBottom:"0px"}}>
                      Call Time
                      </label>
                    <select id="meetingtime" name="meetingtime" value={values.meetingtime} onChange={handleChange} onBlur={handleBlur} style={{ display: "block",height:"38px" }} >
                      <option value="" label="Select Time" />
                      <option value="09:00:00">9:00am (EDT)</option>
                      <option value="09:30:00">9:30am (EDT)</option>
                      <option value="10:00:00">10:00am (EDT)</option>
                      <option value="10:30:00">10:30am (EDT)</option>
                      <option value="11:00:00">11:00am (EDT)</option>
                      <option value="11:30:00">11:30am (EDT)</option>
                      <option value="12:00:00">12:00pm (EDT)</option>
                      <option value="12:30:00">12:30pm (EDT)</option>
                      <option value="13:00:00">1:00pm (EDT)</option>
                      <option value="13:30:00">1:30pm (EDT)</option>
                      <option value="14:00:00">2:00pm (EDT)</option>
                      <option value="14:30:00">2:30pm (EDT)</option>
                      <option value="15:00:00">3:00pm (EDT)</option>
                      <option value="15:30:00">3:30pm (EDT)</option>
                      <option value="16:00:00">4:00pm (EDT)</option>
                      <option value="16:30:00">4:30pm (EDT)</option>
                      <option value="17:00:00">5:00pm (EDT)</option>
                      <option value="17:30:00">5:30pm (EDT)</option>
                    </select>
              </Col>
            </Row>
            <Row style={{margin:"14px"}}>
              <Col style={{textAlign:"center"}}>
              <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  Please Contact Me
                </Button>

                <div style={{fontSize:"11px",margin:"10px"}}>
                  By submitting this form, you are requesting to be contacted by a member of the Elevate Sales Team.<br/>
                  Existing subscribers seeking support, please visit the <a href="https://elmstreettechnology.zendesk.com/hc/en-us">Elevate Help Center</a>.
                </div>
              </Col>
            </Row>
           

            </div>
            
          </Form>

            </Col>


          </Row>
          </div>
        )}
      />
      )
    }
}

export default FormLanding