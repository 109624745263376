import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import moment from "moment";

class Formchecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formState: null,
    };
  }

  render() {
    const { formState } = this.state;

    if (formState === "success") {
      return (
        <Row>
        <Col>
        <div style={{textAlign:"center"}}>
        <div style={{margin:"50px"}}>
            <Button href="/assets/elevate_brokerage_tech_checklist.pdf">Download the PDF</Button>
            </div>
        <h5>Fantastic!</h5>
        We’ll reach out to you asap via email or telephone.<br/>
              You can also speak to a member of our sales team immediately by calling  <a href="tel:18339781196">833.978.1196</a>.
          </div>
        </Col>
    </Row>
      );
    } else if (formState === "error") {
      return (
        <div style={{ maxWidth: "400px", margin: "24px auto" }}>
          <Alert color="error">An error occurred.</Alert>
        </div>
      );
    }

    return (
      <div >
        <Formik
        enableReinitialize 
          initialValues={{
            firstname: this.props.firstname,
            lastname: this.props.lastname,
            company: "",
            email: this.props.email,
            phone: this.props.phone,
            mls_number: "",
            form: "brokerchecklist",
            list: 116170,
            meetingdate: '',
            meetingtime: '',
          }}
          validationSchema={() =>
            Yup.object().shape({
              firstname: Yup.string().required("First name is required"),
              lastname: Yup.string().required("Last name is required"),
              company: Yup.string(),
              email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
              phone: Yup.string().required("Phone is required"),
              mls_number: Yup.string()
            })
          }
          onSubmit={(values, { setSubmitting }) => {
            //if (values.meetingdate != undefined) {
            var meeting_request = '';
            var notes = 'Notes: ';
            //console.log(values);
            Object.keys(values).forEach(function (key, index) {
              // key: the name of the object key
              // index: the ordinal position of the key within the object 
              if (key === 'meetingdate') {
                if (typeof values.meetingdate === "string") {
                  var this_date = moment(values.meetingdate).format('YYYY-MM-DD'); 
                  meeting_request = this_date + "T" + values.meetingtime.replace(" (EDT)", "") + "-04:00"
                }
                if (typeof values.meetingdate.format === "function") {
                  meeting_request = values.meetingdate.format("YYYY-MM-DD") + "T" + values.meetingtime.replace(" (EDT)", "") + "-04:00"
                }
              }
              if (key === 'roleOther'){
                if(values.roleOther.length > 0){
                  values.role = values.roleOther;
                }
              }
              if (key === 'interestsOther'){
                if(values.interestsOther.length > 0){
                  notes = values.interests.join(",");
                  notes = notes + "," + values.interestsOther;
                }
              }
            });
            
            const body = {
              ...values,
              notes: notes,
              utm_campaign: (window.utm_tags) ? window.utm_tags.campaign : "",
              utm_source: (window.utm_tags) ? window.utm_tags.source : "",
              utm_medium: (window.utm_tags) ? window.utm_tags.medium : "",
              utm_term: (window.utm_tags) ? window.utm_tags.term : "",
              demo_request_date: meeting_request
            };
            return fetch(
              "https://easyemerge.com/plugins/elevate_form.php",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(body),
              }
            )
              .then((response) => response.json())
              .then((res) => {
                if (res.message === "ok") {
                  this.setState({ formState: "success" });
  
                  if (window.fbq) {
                    window.fbq("track", "Lead");
                  }
                  if (window.gtag) {
                    window.gtag("event", "conversion", {
                      send_to: "AW-980447970/2_aHCOnsjo8BEOLlwdMD",
                    });
                  }
                  if (window.ga) {
                    window.ga('send','event','form','form_completed','boss');
                  }
                } else {
                  this.setState({ formState: "error" });
                }
              })
              .catch((err) => {
                this.setState({ formState: "error" });
              });
          }}
          render={({ values, isSubmitting,handleBlur, handleChange }) => (
            <Form noValidate style={{marginTop:"30px"}}>
              <Row style={{margin:"10px"}}>
              <Col md>
              <Field
                id="firstname"
                name="firstname"
                placeholder="First Name"
                className="form-control"
                value={values.firstname}
              />
              </Col>
              <Col md>
              <Field
                id="lastname"
                name="lastname"
                label="Last Name"
                placeholder="Last Name"
                className="form-control"
                value={values.lastname}
              />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              className="form-control"
              value={values.email}
            /></Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="phone"
              name="phone"
              label="Phone"
              placeholder="Phone"
              className="form-control"
              type="tel"
              value={values.phone}
            />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="company"
              name="company"
              label="Affiliation (optional)"
              placeholder="Affiliation (optional"
              className="form-control"
            />
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
                <Field
                    id="mls_number"
                    name="mls_number"
                    placeholder="MLS # (optional)"
                    className="form-control"
                />
            </Col>
            </Row>
                

              <Field id="demorequest" name="demorequest" type="checkbox" value="yes" style={{marginLeft:"0px",marginRight:"15px"}} />
              <span style={{fontSize:"16px"}}>Schedule a 15-minute demo of Elevate</span>
              <div style={{marginTop:"30px",marginBottom:"30px",textAlign:"center"}}>
              <Button
                type="submit"
                disabled={isSubmitting}
                style={{width:"50%",backgroundColor:"#5bbfb7",borderColor:"#5bbfb7"}}
              >
                Download
              </Button>
              </div>
             
              <div style={{fontSize:"11px", lineHeight:"16px"}}>
              By submitting this form, you are requesting to be contacted by a member of the Elevate Sales Team at the details provided via text, email or call (may involve automated or pre-recorded means).  You may revoke this consent through any reasonable means.
                Existing subscribers seeking support, please visit the <a href="https://elmstreettechnology.zendesk.com/hc/en-us">Elevate Help Center</a>.
              </div>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default Formchecklist;
