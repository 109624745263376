import React, { Component } from "react"
import LayoutForwardFacing from "../components/layout_forwardfacing"
import SEO from "../components/seo"
import PageContent from "../components/page_content"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed' 
import FormLanding from "../components/form_landing"
import FormLandingInitial from "../components/form_landing_initial"
import FormLandingBrainstorm from "../components/form_brainstorm_initial"
import FormChecklist from "../components/form_checklist";
import Form27 from "../components/form_27";
import FormBrainstorm from "../components/form_brainstorm";
import Screenshot from "../images/pdf_screenshot.png"
import Checklist from "../images/checklist_cover.jpg"
import BrainstormGif from "../images/brokerage_brainstorm.gif";
import "../components/index.css"
import { relativeTimeRounding } from "moment"
 
class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
    };

    this.onfirstnameChanged = this.onfirstnameChanged.bind(this);
    this.onlastnameChanged = this.onlastnameChanged.bind(this);
    this.onemailChanged = this.onemailChanged.bind(this);
    this.onphoneChanged = this.onphoneChanged.bind(this);
  }

  onfirstnameChanged(name){
    this.setState({firstname: name});
    return name;
  }
  onlastnameChanged(name){
    this.setState({lastname: name});
  }
  onemailChanged(value){
    this.setState({email: value});
  }
  onphoneChanged(value){
    this.setState({phone: value});
  }

  render(){

   return (
     <div style={{position:"relative",height:"100%"}}>
       {/*
       <FormLandingBrainstorm onfirstnameChanged={this.onfirstnameChanged} onlastnameChanged={this.onlastnameChanged} onemailChanged={this.onemailChanged} onphoneChanged={this.onphoneChanged} />
       */}
        <LayoutForwardFacing>
          <SEO title="Home" />
          
          <PageContent>
            
            <Container>
              {/*
              <Row>
                <Col>
                  <div style={{textAlign:"center"}}>
                    <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Meet the <span style={{color:"#f05953"}}>BOSS</span></h1>
                    </div>
                    <span style={{color:"#2f2574"}}>
                      </span>

                    <div className="fullWidthVid">
                    <ResponsiveEmbed aspectRatio="16by9">
                    <iframe title="How independent real estate brokerages are winning the war against the big boys" src="https://www.youtube-nocookie.com/embed/vd7DeTuNb6w" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </ResponsiveEmbed>
                    </div>

                  </div>
                </Col>
              </Row>
              */}

              {/*
              <Row>
                <Col>
                  <hr/>
                  <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Brokerage Brainstorm:  Alternative Solutions to Every Day Challenges</h1>
                      <h5>8-week video series NOW AVAILABLE</h5>
                    </div>
                </Col>
              </Row>
              <Row style={{marginTop:"30px",marginBottom:"30px"}}>
                <Col md={6}>
                  <img src={BrainstormGif} alt="Brainstorm Logo" style={{width:"100%"}}/><br/>
                  <ResponsiveEmbed aspectRatio="16by9">
                    <iframe title="Brainstorm" src="https://www.youtube-nocookie.com/embed/O-6jRbgLEdo" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </ResponsiveEmbed>
                </Col>
                <Col md={6}>
                  <div style={{textAlign:"center",marginBottom:"30px"}}>
                  
                  This 8-week educational video series led by industry experts - Frank Chimento &amp; Mike Martella - 
                  dives into the nuts &amp; bolts of running a successful real estate brokerage with each episode focused
                   on a specific segment of your business:
                  </div>
                  <div style={{fontSize:"14px"}}>
                   <ol>
                   <li>Recruiting 101 - What's most important to win a recruiting battle</li>
                   <li>Cutting costs on services, good idea or bad idea</li>
                   <li>Recruiting 2.0 - Whomever controls the leads, attracts the agents</li>
                   <li>Big brother vs big partner - How do your agents view your brokerage? </li>
                   <li>Creative Services Agency - Are you creating the best collateral and corporate branding packages? How are your recruiting materials? </li>
                   <li>Consolidating technology - Can your tech offerings save you money and make you money</li>
                   <li>Service Models - What are agents wanting the most from their brokerage</li>
                   <li>Recruiting - It's all about your BS: Belief Systems.</li>
                   </ol>
                   </div>
                   <div style={{textAlign:"center",marginBottom:"30px"}}>
                   <p>
                   SUBSCRIBE TODAY to gain access:</p>
                  <FormBrainstorm />
                  </div>
                </Col>
              </Row>
              */}


              <Row >
                <Col>
                  <hr/>
                  <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Be the <span style={{color:"#f05953"}}>BOSS</span> (of Recruiting)</h1>
                      <p>Free Download: "The 27 Most Influential Words that Brokers Should Use When Recruiting"</p>
                    </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} style={{textAlign:"center"}}>
                
                    <img src={Screenshot} alt="PDF Screenshot" style={{width:"80%",marginLeft:"auto",marginRight:"auto"}}/>
                
                </Col>
                <Col md={6} style={{textAlign:"center"}}>
                Created by VP of Brokerage Development and linguistics expert Frank Chimento, this popular guide will introduce you to approaching recruitment and retention conversations from a position of persuasion and influence.  
                A "must-read" for all brokers.
                <Form27 firstname={this.state.firstname} lastname={this.state.lastname} email={this.state.email} phone={this.state.phone}  />
                </Col>
              </Row>
              <Row >
                <Col>
                  <hr/>
                  <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Brokerage Technology Checklist</h1>
                      <p>Free Download</p>
                    </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} style={{textAlign:"center"}}>
                Download your comprehensive guide to selecting the right technology partner for your brokerage. 
                Includes the top 4 questions to ask tech companies to separate potential partners from vendors, and a checklist of features &amp; functionality every brokerage must have!
                <FormChecklist firstname={this.state.firstname} lastname={this.state.lastname} email={this.state.email} phone={this.state.phone}  />
                </Col>
                <Col md={6} style={{textAlign:"center"}}>
                  <img src={Checklist} alt="PDF Tech Chechlist" style={{width:"80%",marginLeft:"auto",marginRight:"auto"}}/>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr/>
                  <div style={{textAlign:"center"}}>
                    <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Tour the <span style={{color:"#f05953"}}>BOSS</span></h1>
                    </div>
                    <p>Request your confidential consultation with our brokerage development team using the form below,<br/>or call 833.978.1196 to speak to us today.</p>
                  </div>
                  <div style={{width:"80%",marginLeft:"auto",marginRight:"auto"}}>
                  <FormLanding firstname={this.state.firstname} lastname={this.state.lastname} email={this.state.email} phone={this.state.phone} />
                  </div>
                  
                </Col>
              </Row>
  
              
              <Row >
                <Col>
                  <hr/>
                  <div style={{textAlign:"center",margin:"30px"}}>
                      
                      <h1>Explore <span style={{color:"#f05953"}}>MORE</span></h1>
                      <p>Call 833.978.1196 to speak with a member of our brokerage development team. We're here to help.</p>
                    </div>
                </Col>
              </Row>
              { /*
              <Row style={{marginTop:"30px",marginBottom:"30px"}}>
                <Col md={6}>
                  <ResponsiveEmbed aspectRatio="16by9">
                    <iframe title="Introduction to BOSS from Elevate" src="https://www.youtube-nocookie.com/embed/YgFbo7RLAwU" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </ResponsiveEmbed>
                </Col>
                <Col md={6}>
                  <div style={{textAlign:"center",marginTop:"30px"}}>
                    <h4>Short &amp; Sweet</h4>
                    Prem Luthra (Elevate CEO) &amp; Frank Chimento (VP of Broker Development) discuss the benefits of Elevate BOSS<sup>&trade;</sup>, 
                    along with the key differentiating factors of this long-needed solution for real estate brokers &amp; agents.
                    <br/><br/>
                    Runtime: 4:41

                  </div>
                </Col>
              </Row>
              
              <Row style={{marginTop:"30px",marginBottom:"30px"}}>
                <Col md={6}>
                <div style={{textAlign:"center",marginTop:"30px"}}>
                    <h4>Deep Dive</h4>
                    Bondilyn Jolly (VP of Marketing), Frank Chimento (VP of Broker Development), &amp; Justin Falb 
                    (Managing Broker of Oceans Luxury Real Estate) discuss the challenges of independent brokerages and how 
                    they are winning the war against the ‘big boys’ in terms of recruiting, retention &amp; remaining profitable in a time of shrinking margins.
                    <br/><br/>
                    Runtime:  1 hour
                  </div>
                </Col>
                <Col md={6}>
                  <ResponsiveEmbed aspectRatio="16by9">
                    <iframe title="How independent real estate brokerages are winning the war against the big boys" src="https://www.youtube-nocookie.com/embed/OEsIlXgI444" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  </ResponsiveEmbed>
                </Col>
              </Row>
              */ }
            </Container>
          {/* <Link to="/page-2/">Go to page 2</Link> */}
          </PageContent>
        </LayoutForwardFacing>
        </div>
      )
}}

export default IndexPage
