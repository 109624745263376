import React, { Component } from "react"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import "../components/form_landing_initial.css"
import BossGif from "../images/BOSS_OPEN_2.gif"


class FormLandingInitial extends Component {
    constructor(props) {
      super(props);
      this.state = {
        formState: null,
        showError: false,
      };
    }

    componentDidMount(){
        // Get the modal
        var modal = document.getElementById("myModal");
    
        // When the user clicks anywhere outside of the modal, close it
       // window.onclick = function(event) {
       //   if (event.target == modal) {
            //modal.style.display = "none";
        //  }
        //}
      }

    closeModal(){
      var modal = document.getElementById("myModal");
      modal.style.display = "none";
    }
  
    render() {
      const { formState } = this.state;
      var showError = false;
 
      if (formState === "success") {
        return (
          <div />
        );
      } else if (formState === "error") {
          showError = this.state.showError;
      }

      return (
        <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          form: "boss_form_initial",
          list: 85576,
        }}
        validationSchema={() =>
          Yup.object().shape({
            firstname: Yup.string().required("First name is required"),
            lastname: Yup.string().required("Last name is required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("Email is required"),
            phone: Yup.string().required("Phone is required")
          })
        }
        onSubmit={(values, { setSubmitting }) => {
          
          const body = {
            ...values,
            utm_campaign: (window.utm_tags) ? window.utm_tags.campaign : "",
            utm_source: (window.utm_tags) ? window.utm_tags.source : "",
            utm_medium: (window.utm_tags) ? window.utm_tags.medium : "",
            utm_term: (window.utm_tags) ? window.utm_tags.term : "",
          };

          this.props.onfirstnameChanged(values.firstname);
          this.props.onlastnameChanged(values.lastname);
          this.props.onemailChanged(values.email);
          this.props.onphoneChanged(values.phone);


          return fetch(
            "https://easyemerge.com/plugins/elevate_form.php",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
              body: JSON.stringify(body),
            }
          )
            .then((response) => response.json())
            .then((res) => {
              if (res.message === "ok") {
                this.setState({ formState: "success" });
                console.log("success");
                this.setState({ showError: false });
              } else {
                this.setState({ formState: "error" });
                this.setState({ showError: true });
                setSubmitting(false);
                console.log('error');
              }
            })
            .catch((err) => {
              this.setState({ formState: "error" });
              this.setState({ showError: true });
              setSubmitting(false);
            });
        }}

        render={({ values, isSubmitting,showError, errors,touched }) => (
          <div class="modalTakeover" id="myModal">
              <div class="modalTakeoverInner">
          <Row>
              <Col >
              <img src={BossGif} style={{marginBottom:"0px"}} className="aniGif"/>
              </Col>
          </Row>
          <Row>
            <Col>
                <h1 className="initialHeading">Are you ready to meet the BOSS?</h1>
                <p>Broker/Owners - Request your <span style={{color:"#CC0000"}}>CONFIDENTIAL</span> one-on-one consultation today &amp; receive a copy<br/>
                 of our popular "Recruiting:  The 27 Most Influential Words Brokers Need to Know".</p>
                { showError && (
                    <p>There was an error processing your form</p>
                ) }
            </Col>
        


          </Row>
          <Row>
            <Col >
            <Form noValidate>
            <div style={{ marginLeft:"auto",marginRight:"auto"}}>
            <Row style={{margin:"10px"}}>
              <Col md>
              
              <Field
                id="firstname"
                name="firstname"
                placeholder="First Name"
                className="form-control mobile-control-padding"
              />
              {touched.firstname && errors.firstname ? <div>{errors.firstname}</div> : null}
              </Col>
              <Col md>
              <Field
                id="lastname"
                name="lastname"
                label="Last Name"
                placeholder="Last Name"
                className="form-control"
              />
              {touched.lastname && errors.lastname ? <div>{errors.lastname}</div> : null}
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="email"
              name="email"
              label="Email"
              placeholder="Email"
              className="form-control"
            />
            {touched.email && errors.email ? <div>{errors.email}</div> : null}
            </Col>
            </Row>
            <Row style={{margin:"10px"}}>
              <Col>
            <Field
              id="phone"
              name="phone"
              label="Phone"
              placeholder="Phone"
              className="form-control"
              type="tel"
            />
            {touched.phone && errors.phone ? <div>{errors.phone}</div> : null}
            </Col>
            </Row>
            
            <Row style={{margin:"14px"}}>
              <Col style={{textAlign:"center"}}>
              <Button
                  type="submit"
                  disabled={isSubmitting}
                >
                  Show me who's BOSS
                </Button>
              </Col>
            </Row>
           

            </div>
            
          </Form>

            </Col>


          </Row>
          <Row>
            <Col style={{textAlign:"center",fontSize:"14px"}}>
              <a href="#" onClick={ (e) => this.closeModal()} style={{textDecoration:"none",color:"#1e1749"}}>No thanks, just take me to the site.</a>
            </Col>
          </Row>
          </div>
          </div>
        )}
      />
      )
    }
}

export default FormLandingInitial